import PropTypes from 'prop-types';
import React from "react"

class Step2 extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="step step2">

        <div className="row">
          <div className="col-md-6">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 4</h6>
              <h4>What are your monthly business expenses?</h4>
              <p>Do you have fixed business expenses per month? This could be things like software subscriptions, office space, or hosting costs.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="step-content">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-row">
                  <div className="col-12">
                    <label>Account Balance</label>
                    <span className="balance value">${this.props.calculatorContext.accountBalance.toLocaleString()}</span>
                  </div>
                  <div className="col-12">
                    <label className="" htmlFor="step2">Monthly Business Expenses</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" step="0.01" className="form-control" name="monthlyExpenses" id="step2" placeholder="0.00" />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          /month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary w-100">Next</button>
              </form>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default Step2


