import PropTypes from 'prop-types';
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const calculateRunwayLevel = (runway) => {
  if (runway >= 0 && runway <= 1) {
    return 0
  }
  else if (runway >= 1 && runway <= 4) {
      return 1
  }
  else if (runway >= 4 && runway <= 8) {
      return 2
  }
  else if (runway >= 8){
      return 3
  }
}


const RenderRunwayLevel = (balance, expenses, salary, runway) => (
  <StaticQuery
      query={graphql`
        query {
          runwayCalculatorChart: file(relativePath: { eq: "runway-calculator-chart.png" }) {
            childImageSharp{
              fluid(quality:60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        
          runwayResultsLevel0: file(relativePath: { eq: "calculator-results/level0.png" }) {
            childImageSharp{
              fluid(quality:60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        
          runwayResultsLevel1: file(relativePath: { eq: "calculator-results/level1.png" }) {
            childImageSharp{
              fluid(quality:60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        
          runwayResultsLevel2: file(relativePath: { eq: "calculator-results/level2.png" }) {
            childImageSharp{
              fluid(quality:60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        
          runwayResultsLevel3: file(relativePath: { eq: "calculator-results/level3.png" }) {
            childImageSharp{
              fluid(quality:60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}

      render={data => {

        let runwayLevel = calculateRunwayLevel(runway)
        let levelsContent = {
          0: {
            image: data.runwayResultsLevel0.childImageSharp.fluid,
            description: "Time to hustle! You don't have enough money to pay yourself next month.",
            busy: "Consider raising your rates to better fit your needs. Think about different ways you can implement value-based pricing, or explore additional revenue streams.",
            slow: "You've got this! Try reaching out to past clients, ask for referrals, or start attending local networking events. Look for ways to connect with busy freelancers who may have overflow work. Remember; it takes time and effort to build up your freelance network, so keep at it!",
            color: "red",
          },
          1: {
            image: data.runwayResultsLevel1.childImageSharp.fluid,
            description: `You've got this! You have enough money to pay yourself $${salary} for the next ${runway} months.`,
            busy: "Keep taking that work! Your runway will grow with you.",
            slow: "Try reaching out to past clients, ask for referrals or start attending local networking events.",
            color: "yellow",
          },
          2: {
            image: data.runwayResultsLevel2.childImageSharp.fluid,
            description: `Nice work! You have enough money to pay yourself $${salary} for the next ${runway} months.`,
            busy: "You've earned a vacation - consider taking some time off, to recharge and reflect on your success.",
            slow: "Feel comfortable taking some time to relax and refocus. This could mean working on a side project, or investing in new skills.",
            color: "teal",
          },
          3: {
            image: data.runwayResultsLevel3.childImageSharp.fluid,
            description: `Wow! You have enough money to pay yourself $${salary} for the next ${runway} months.`,
            busy: "It's time to start thinking about the future of your business. Do all of your current projects align with your long term career goals? If not, consider dropping projects that you are no longer invested in.",
            slow: "Enjoy the freelance lifestyle you've worked so hard achieve! Take an extended vacation, work on a side project, or invest in learning some new skills.",
            color: "green",
          },
        }
        let level = levelsContent[runwayLevel]
        return (
          <div className={`row step-description calculator-results ${level.color}`}>
            <div className="col-md-6 my-auto">
              <div className="title">
                <h6>Your Runway</h6>
                <h2>{runway} months</h2>
              </div>
            </div>

            <div className="col-md-6">
              <Img className="calculator-result-img" fluid={level.image} />
            </div>
            <div className="col-md-12 mt-5">
              <p>{level.description}</p>

              <h6>If You're Busy</h6>
              <p>{level.busy}</p>

              <h6>If You're Slow</h6>
              <p>{level.slow}</p>
            </div>
          </div>
        )
      }}
    />
)

class Step4 extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }

  componentDidMount() {
  }

  propsToRunway = () => {
    return this.calculateRunway(this.props.calculatorContext.accountBalance,
                                this.props.calculatorContext.monthlyExpenses,
                                this.props.calculatorContext.salaryTarget)
  }

  calculateRunway = (balance, expenses, salary) => {
    let monthlyCost = salary + expenses
    let runway = (balance / monthlyCost).toFixed(1)
    return runway
  }

  render() {
    let balance = this.props.calculatorContext.accountBalance
    let expenses = this.props.calculatorContext.monthlyExpenses
    let salary = this.props.calculatorContext.salaryTarget
    let runway = this.propsToRunway()

    return (
      <div className="step step4">

        <div className="row">
          <div className="col-md-6">
            {RenderRunwayLevel(balance, expenses, salary, runway)}
          </div>
          <div className="col-md-6">
            <div className="step-content results">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-row">
                  <div className="col-12">
                    <label htmlFor="step1">Bank Account Balance</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" step="0.01" className="form-control" name="accountBalance" id="step1" placeholder="Account Balance" defaultValue={this.props.calculatorContext.accountBalance} />
                    </div>
                  </div>

                  <div className="col-12">
                    <label className="" htmlFor="step2">Monthly Business Expenses</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" step="0.01" className="form-control" name="monthlyExpenses" id="step2" placeholder="Monthly Business Expenses" defaultValue={this.props.calculatorContext.monthlyExpenses} />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          /month
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label className="" htmlFor="step4">Monthly Paycheck</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" step="0.01" className="form-control" name="salaryTarget" id="step4" placeholder="Monthly Salary" defaultValue={this.props.calculatorContext.salaryTarget} />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          /month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary w-100">Re-calculate Runway</button>
              </form>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default Step4


