import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'

import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"
import SEO from "../components/marketing/seo"

import MultiStepCalculator from "../components/marketing/MultiStepCalculator/MultiStepCalculator.jsx"

import "../scss/pages/RunwayCalculator.scss"


export const pageQuery = graphql`
query {
  runwayCalculatorChart: file(relativePath: { eq: "runway-calculator-chart.png" }) {
    childImageSharp{
      fluid(quality:60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
class RunwayCalculator extends React.Component {
  render() {
    let runwayCalculatorChart = this.props.data.runwayCalculatorChart.childImageSharp.fluid
    return (
      <React.Fragment>
        <SEO title={`Free Runway Calculator - Hammock`}
             description={`Use this free tool to calculate your runway. Once you know your runway, you will be equipped to handle the ups and downs of freelancing.`}
             keywords={['freelance', 'freelancers', 'freelance business', 'runway', 'freelance runway', 'freelance forecasting', 'expense tracking', 'expenses', 'freelance taxes', 'freelance quarterly estimated taxes', 'freelance tax' ]}
        />

        <Header />
        <div className="runway-calculator-blurb section bottom-border white">
          <div className="container px-4 px-sm-0">
            <div className="row">
              <div className="col-md-5">
                <h2>Do you know your runway?</h2>
                <p>Your runway is the number of months you can give yourself a consistent paycheck, without extra income.</p>
                <p>Once you know your runway, you will be equipped to handle the ups and downs of freelancing. Whether you are preparing for a dry spell, or taking a vacation; you'll have the peace of mind knowing that you'll get paid.</p>

                <p>Below we've created a calculator to make it easy for you to figure out your runway. Still not convinced? Check out our blog post on <Link to="/blog/2019/07/09/3-reasons-to-calculate-your-freelance-runway-today">why every freelancer should know their runway</Link>.</p>
              </div>

              <div className="col-md-6 offset-md-1 my-auto pt-5 pt-sm-0">
                <Img className="hero-chart mb-5" fluid={runwayCalculatorChart} />
                <h6 className="step-label">Why calculate your runway?</h6>
                <ul>
                  <li>Improve your work-life balance</li>
                  <li>Know when you need to find new clients</li>
                  <li>Have confidence passing on projects that aren't a good fit</li>
                  <li>Give yourself paid time off</li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      

        <div className="runway-calculator section half-tone">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h1 className="runway-title">Runway Calculator</h1>
                <MultiStepCalculator />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default RunwayCalculator
