import PropTypes from 'prop-types';
import React from "react"

class Step1 extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="step step1">

        <div className="row">
          <div className="col-md-6">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 4</h6>
              <h4>What is your bank account balance?</h4>
              <p>How much money is in your bank account after taxes have been taken out?</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="step-content">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-row">
                  <div className="col-12">
                    <label htmlFor="step1">Bank Account Balance</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" step="0.01" className="form-control" name="accountBalance" id="step1" placeholder="0.00" />
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary w-100">Next</button>
              </form>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default Step1


