import React from "react"

import Step1 from './Step1.jsx'
import Step2 from './Step2.jsx'
import Step3 from './Step3.jsx'
import Step4 from './Step4.jsx'

import "./MultiStepCalculator.scss"
import SteppedProgressBar from '../SteppedProgressBar/SteppedProgressBar.jsx';

class MultiStepCalculator extends React.Component {

  constructor(props) {
    super(props);

    // NOTE: need to append, because gatsby hijacks the component state.
    this.state =  {
      step: 1,
      accountBalance: 0,
      monthlyExpenses: 0,
      salaryTarget: 0,
    }
  }

  componentDidMount() {

  }

  handleStep1 = (e) => {
    e.preventDefault()
    let balance = Number(e.target.accountBalance.value)
    this.setState({
      accountBalance: balance,
      step: 2
    })
  }

  handleStep2 = (e) => {
    e.preventDefault()
    this.setState({
      monthlyExpenses: Number(e.target.monthlyExpenses.value),
      step: 3
    })
  }

  handleStep3 = (e) => {
    e.preventDefault()
    this.setState({
      salaryTarget: Number(e.target.salaryTarget.value),
      step: 4
    })
  }

  handleStep4 = (e) => {
    e.preventDefault()
    this.setState({
      accountBalance: Number(e.target.accountBalance.value),
      monthlyExpenses: Number(e.target.monthlyExpenses.value),
      salaryTarget: Number(e.target.salaryTarget.value),
    })
  }

  handleProgressBarClick = (e) => {
    let stepNumber = Number(e.target.dataset.step)
    this.setState({step: stepNumber})
  }

  
  renderStep = () => {
    switch(this.state.step) {
      case 1:
        return <Step1 handleSubmit={this.handleStep1} calculatorContext={this.state} />
      case 2:
        return <Step2 handleSubmit={this.handleStep2} calculatorContext={this.state} />
      case 3:
        return <Step3 handleSubmit={this.handleStep3} calculatorContext={this.state} />
      case 4:
        return <Step4 handleSubmit={this.handleStep4} calculatorContext={this.state} />
      default:
        return <Step1 handleSubmit={this.handleStep1} calculatorContext={this.state} />
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="multistep-runway-calculator">
            <SteppedProgressBar stepCount={4} currentStep={this.state.step} handleProgressBarClick={this.handleProgressBarClick}/>
            {this.renderStep()}
            <p className="disclaimer"><em>Disclaimer: This tool should not be considered financial or tax advice. If you need advice, please seek the help of a certified professional.</em></p>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiStepCalculator


